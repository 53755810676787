<template>
<div class="b-home p-3 pb-5">
  <div class="bd-info bd-card rounded-3 bg-light mb-3">
    <div class="bd-card-title p-3 d-flex align-items-center">
      <b-icon icon="info" class="text-primary mr-2" /> {{ info.data.judul }}
    </div>
    <div class="bd-card-content">
      <b-img :src="info.data.gambar " fluid class="w-100"/>
      <span class="px-3 pt-3 d-block">
        {{dateFormat(info.data.created_date)}}
      </span>
      <p class="px-3 pt-3">
        {{info.data.pesan}}
      </p>
    </div>
  </div>
</div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "@/axios";
export default {
  data: () => ({
    info: {
      data: [],
      loading: false
    }
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getInfo() {
      this.info.loading = true;
      let url = `/information/info_detail?id_info=${this.$route.params.id}`;
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let req = await axios.get(url, header);
      if (req.data.status) {
        this.info.data = req.data.msg
        this.info.loading = false;
      } else {
        this.info.loading = false;
      }
    },
    dateFormat(date) {
      let theDate = new Date(date)
      let days = ['Minggu','Senin','Selasa','Rabu','Kamis','Jumat','Sabtu']
      let day = theDate.getDay()
      let d = theDate.getDate()
      let m = theDate.getMonth() + 1
      let y = theDate.getFullYear()
      return `${days[day]}, ${d}-${m}-${y}`
    }
  },
  mounted(){
    this.getInfo()
  }
}
</script>
